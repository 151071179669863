import React, { useState } from "react";
import "./Registration.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdEmail } from "react-icons/md";
import kredKonnectDemo from "../../assets/kred-konnect.png";
import { API_URL } from "../../config";
import { FaUser, FaMobileAlt, FaUserTie, FaUserLock ,FaWhatsapp } from "react-icons/fa";

import { IoIosCheckbox } from "react-icons/io";

function Registration() {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phoneno: "",
    brokername: "",
    clientcode: "",
    agreeToTerms: false,
    type: "",
    gender: "",
    totp: "",
  });
  const [registrationMessage, setRegistrationMessage] = useState("");
  const brokerNames = ["Ashta"];

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    let inputValue = type === "checkbox" ? checked : value;

    if (type === "text" && name === "email") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailPattern.test(value)) {
        toast.error("Invalid email format");
        return;
      }
    }

    if (type === "number" && name === "phoneno") {
      const mobilePattern = /^\d{10}$/;
      if (value.length > 10) {
        if (!mobilePattern.test(value)) {
          toast.error("Mobile number should be 10 digits");
          return;
        }
      }
    }

    if (type === "checkbox" && name === "type") {
      setFormData({
        ...formData,
        type: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: inputValue,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formDataToSend = new FormData();

      formDataToSend.append("first_name", formData.name);
      formDataToSend.append("last_name", formData.name);
      formDataToSend.append("phone_number", formData.phoneno);
      formDataToSend.append("clientCode", formData.clientcode);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("broker", formData.brokername);
      formDataToSend.append("totp", formData.totp);

      let response = await fetch(API_URL + "register/?code=production", {
        method: "POST",
        body: formDataToSend,
      });
      console.log(response);
      response = await response.json();
      if (response.clientCode === formData.clientcode) {
        toast.success("Registration successful");
        let redirect = () => {
          window.location.replace("https://kredkonect.com");
        };
        setTimeout(redirect, 2000);
      } else {
        toast.error("Registration failed " + response?.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while registering " + error);
    }
  };
  return (
    <div className="register-page">
      <ToastContainer />
      <div className="main-container">
        <div className="image-container">
          <img src={kredKonnectDemo} alt="KredKonnect demo" className="" />
        </div>
        <div className="form-container">
          <div className="form-logo-container">
            <img
              src="https://www.kredallino.tech/images/logo-pink.png"
              alt="KredKonnect Logo"
              height={40}
              width={160}
            />
          </div>
          <h1 className="form-title">Registration Form</h1>
          <form onSubmit={handleSubmit} className="registeration-form">
            <div className="form-group">
              <label>
                <FaUser size={25} />
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                placeholder="Name"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>
                <MdEmail size={25} />
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                placeholder="Email"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>
                <FaMobileAlt size={25} />
              </label>
              <input
                type="text"
                name="phoneno"
                value={formData.phoneno}
                placeholder="Registered Mobile Number"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>
                <FaUserTie size={25} />
              </label>
              <select
                name="brokername"
                value={formData.brokername}
                onChange={handleInputChange}>
                <option value="" disabled>
                  Select a broker
                </option>
                {brokerNames.map((broker) => (
                  <option key={broker} value={broker}>
                    {broker}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>
                <FaUserLock size={25} />
              </label>
              <input
                type="text"
                name="clientcode"
                value={formData.clientcode}
                placeholder="Client Code"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>
                <FaUserLock size={25} />
              </label>
              <input
                type="text"
                name="totp"
                value={formData.totp}
                placeholder="Totp Code"
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group-box">
              <label>
                <input type="checkbox" className="check-box" />
                By clicking&nbsp;{" "}
                <a
                  href="https://www.shubhyadav.tech/forms/disclamer.html"
                  target="_blank"
                  rel="noopener noreferrer">
                  {" "}
                  Register{" "}
                </a>
                , you agree to our
                <a
                  href="https://www.kredallino.tech/refund.html"
                  target="_blank">
                  {" "}
                  &nbsp;terms and conditions
                </a>
                .
              </label>
            </div>

            <div className="registration-container">
      <button type="submit" className="register-button">
        Register
      </button>
      <button className="whatsapp-button">
        <a
          href="https://api.whatsapp.com/send/?phone=7067308746&text=Hi%20Sir%20,%20I%20Need%20Help%20In%20Registration%20&type=phone_number&app_absent=0"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-link"
        >
          <FaWhatsapp className="whatsapp-icon" />
          Whatsapp Support
        </a>
      </button>
    </div>
          </form>
        </div>
      </div>

      <div className="info-text-container">
        <p className="disclaimer text-center">
          Disclaimer: KredKonnect is a trading tool that integrates with
          TradingView. <br />
          It does not provide financial advice, and all trading involves risk.
          Make sure to <br />
          read and understand the terms and conditions before using the
          software.
        </p>
        <div className="account-section text-center">
          <p>This software is currently available with Astha Trade only.</p>
          <a
            href="https://accounts.rupeezy.in/kyc-onboarding/open-account?c=TvoKJSQ&utm_campaign=RnE&utm_source=dock"
            className="btn btn-primary">
            Open Astha Account
          </a>
        </div>
      </div>

      <footer className="footer">
        <a target="_blank" href="https://kredallino.tech" className="foot">
          &copy; 2023 KredAllino Technologies |
        </a>
        <a
          target="_blank"
          href="https://www.kredallino.tech/privacy.html"
          className="foot">
          Privacy Policy |
        </a>
        <a
          target="_blank"
          href="https://www.kredallino.tech/refund.html"
          className="foot">
          Disclaimer |
        </a>
        <a
          target="_blank"
          href="https://www.kredallino.tech/terms.html"
          className="foot">
          Terms of Use
        </a>
      </footer>
    </div>
  );
}

export default Registration;
