import React from 'react';
import Registration from './components/Registration/Registration';
import { Analytics } from "@vercel/analytics/react"


function App() {
  return (
    <div className="App">
      <Analytics />
      <Registration />
    </div>
  );
}

export default App;
